import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="page-footer">
      <Container>
        <ul className="footer-nav">
          <li>
            <Link to="/about">About</Link>
          </li>
          {/* <li>
            <Link to="/terms">Terms</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li> */}
        </ul>
        <span className="copyright">Copyright 2019</span>
      </Container>
    </footer>
  );
};

export default Footer;
