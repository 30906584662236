import React from "react";
import { Row, Col, Container } from "reactstrap";

const SectionAbouUs = () => {
  return (
    <section className="section section-light section-about-us">
      <Container>
        <h1 className="section-title">KNOW ABOUT US</h1>
        <p className="section-description text-muted">
          There are thousands of offers in the market, thousands of options to
          choose from. What makes us the right choice to deliver for your
          company? Here are some stats at a glance:
        </p>
        <Row>
          <Col md="6" lg="4">
            <div className="known-about-item">
              <div className="item-counter">10</div>
              <h3 className="text-uppercase">
                years in the <br />
                market
              </h3>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="known-about-item">
              <div className="item-counter rotate-1">50+</div>
              <h3 className="text-uppercase">
                successful projects <br />
                delivered
              </h3>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="known-about-item">
              <div className="item-counter rotate-2">
                15<small>K+</small>
              </div>
              <h3 className="text-uppercase">
                hours of software development delivered
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionAbouUs;
