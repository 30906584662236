import React from "react";
import { Row, Col, Container } from "reactstrap";

const SectionPhilosophy = () => {
  return (
    <section className="section section-light section-philosophy">
      <Container>
        <h1 className="section-title">Company/Philosophy</h1>
        <Row>
          <Col md={{ size: 10, offset: 1 }} lg={{ size: 5, offset: 0 }}>
            <p className="section-description text-muted">
              Designing and building enterprise applications today requires more
              than just having deep tech skills. It demands using technology in
              thoughtful, creative and innovative ways to achieve greater
              productivity, customer loyalty and revenue opportunities. It
              requires thinking, designing, collaborating and problem solving
              differently. We know a thing or two about being different.
            </p>
          </Col>
          <Col
            md={{ size: 10, offset: 1 }}
            lg={{ size: 5, offset: 1 }}
            className="mt-sm-3 mt-lg-0"
          >
            <p className="section-description text-muted">
              Successful technologists have diverse skill sets that are
              different from what the industry has relied on in the past. No
              longer are these the days of just having the deepest tech skills,
              tech innovators now need to have a breadth that many simply do not
              possess: cultivated through research and continued learning;
              characterized by tech agnosticism & objectivity, a willingness to
              take responsibility, and authenticity & humility; encompassing new
              techniques in communication like data storytelling; and driven to
              always strive for better results.
            </p>
            <p itemProp="legalName" className="section-description text-muted">
              We at Alienlab have the skills and the experience that can shape
              your product idea into a success story.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionPhilosophy;
