import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { baseUrl } from '../../services/helpers';

const SectionInfo4 = () => {
  return (
    <section className="section section-dark section-info-dark">
      <Container>
        <div className="heading-group">
          <h4 className="group-title">Design Approach - UI/UX Design</h4>
          <p className="section-description">
            As design enthusiasts, we create smart and effective solutions for your business. We make sure the final
            interface is clean, intuitive and extremely easy to use so that you can turn your users into fans of your
            product!
          </p>
        </div>
        <Row>
          <Col md="6" lg="4">
            <img src={baseUrl('img/path_inverse.svg')} alt="platforms" height="70" />
            <h3 className="mt-4 text-uppercase">User-centric design</h3>
            <p className="text-muted">
              As we work on planning the design solutions for the product, we pay extensive attention to the needs,
              wants, and limitations of the end users. This deep understanding of their expectations allows us to create
              a better experience of using our products for each user.
            </p>
          </Col>
          <Col md="6" lg="4">
            <img src={baseUrl('img/path_inverse.svg')} alt="production" className="rotate-45" height="70" />
            <h3 className="mt-4 text-uppercase">UI+prototyping</h3>
            <p className="text-muted">
              We work closely with the Product team to create the product wireframes, mockups and overall structure.
              These mockups are later tested to choose the best option for the product.
            </p>
          </Col>
          <Col md="6" lg="4">
            <img src={baseUrl('img/path_inverse.svg')} alt="production" className="rotate-45" height="70" />
            <h3 className="mt-4 text-uppercase">User testing</h3>
            <p className="text-muted">
              We evaluate the success of any product by offering our users to test it. We see it as an irreplaceable
              usability practice, since our users’ direct input shows how they feel about their experience with us. This
              allows us to make valuable changes that reflect our users’ needs.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionInfo4;
