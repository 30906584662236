import React, { Component } from 'react';
import ContactButton from '../../components/ContactButton';
import BgMask from '../../components/BgMask';
import { baseUrl } from '../../services/helpers';

class Section2 extends Component {
  render() {
    return (
      <section className="section section-secondary section-promo">
        <BgMask imgPath={baseUrl('img/bg_designer.jpg')} size={80} />
        <h2>Our award-winning design team is the reason many clients work with us over and over. </h2>
        <ContactButton color="light">GET IN TOUCH</ContactButton>
      </section>
    );
  }
}

export default Section2;
