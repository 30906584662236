import React from "react";
import { Row, Col, Container } from "reactstrap";
import { baseUrl } from "../../services/helpers";

const SectionBackend = () => {
  return (
    <section className="section section-dark section-technology">
      <Container>
        <Row>
          <Col md={{ size: 4, offset: 1 }} lg={{ size: 4, offset: 0 }}>
            <img
              itemProp="image"
              src={baseUrl("img/technologies.png")}
              alt="technologies"
              className="img-fluid techno-image"
            />
          </Col>
          <Col md={{ size: 10, offset: 1 }} lg={{ size: 7, offset: 1 }}>
            <h1 className="section-title">technology selection</h1>
            <p className="section-description">
              We have professionals from different areas of expertise like big
              data, mobile, cross-platform development, cloud integration and
              many more. This enables us to define approaches that can
              effectively cover our clients’ business needs while staying on
              time and on budget. We suggest the most suitable choices of user
              platforms, architecture (scalable microservices based
              application), development platforms (Meteor, Loopback, Express
              etc.) and hosting (AWS, Gcloud, DigitalOcean etc.), leading the
              process from scratch to post production support.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionBackend;
