import React from 'react';
// import { Row, Col, Container } from 'reactstrap';
// import LearnMore from '../../components/LearnMore';
import BgMask from '../../components/BgMask';
import ContactButton from '../../components/ContactButton';
import { baseUrl } from '../../services/helpers';

const SectionPartners = () => {
  return (
    <section className="section section-secondary section-promo">
      <BgMask imgPath={baseUrl('img/bg_partner_hero.jpg')} size={80} />
      <h2>Our award-winning design team is the reason many clients work with us over and over. </h2>
      <ContactButton color="light">GET IN TOUCH</ContactButton>
    </section>
  );
}

// const SectionPartners = () => {
//   return (
//     <section className="section section-light section-partners">
//       <Container>
//         <h3 className="section-title">OUR PARTNERS</h3>
//         <p className="section-description text-muted">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu, blandit
//           accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae ultrices.
//         </p>
//         <Row className="partner-row mt-5">
//           <Col lg="6" className="partner-image-col extra">
//             <img src={baseUrl('img/partner-1.png')} alt="partner" />
//           </Col>
//           <Col md={{ size: 10, offset: 1 }} lg={{ size: 6, offset: 0 }}>
//             <h3 className="text-uppercase mb-3">We helped X drive 130% more leads through a complete redesign</h3>
//             <p className="text-muted">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu, blandit
//               accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae ultrices.
//             </p>
//             <LearnMore to="/services" />
//           </Col>
//         </Row>
//         <Row className="partner-row">
//           <Col lg="6" className="partner-image-col order-lg-2">
//             <img src={baseUrl('img/partner-2.png')} alt="partner" />
//           </Col>
//           <Col md={{ size: 10, offset: 1 }} lg={{ size: 6, offset: 0 }} className="order-lg-1">
//             <h3 className="text-uppercase mb-3">We enabled a smart X System for X</h3>
//             <p className="text-muted">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu, blandit
//               accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae ultrices.
//             </p>
//             <LearnMore to="/services" />
//           </Col>
//         </Row>
//         <Row className="partner-row">
//           <Col lg="6" className="partner-image-col extra">
//             <img src={baseUrl('img/partner-3.png')} alt="partner" />
//           </Col>
//           <Col md={{ size: 10, offset: 1 }} lg={{ size: 6, offset: 0 }}>
//             <h3 className="text-uppercase mb-3">We helped X drive 130% more leads through a complete redesign</h3>
//             <p className="text-muted">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu, blandit
//               accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae ultrices.
//             </p>
//             <LearnMore to="/services" />
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

export default SectionPartners;
