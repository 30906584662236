import React from 'react';
import { baseUrl } from '../../services/helpers';
import { Row, Col } from 'reactstrap';

const SectionPhotos = () => {
  return (
    <section className="section section-about-photos">
      <div className="about-photos">
        <Row className="photo-row">
          <Col className="photo-col">
            <div style={{ backgroundImage: `url(${baseUrl('img/about_photo_1.jpg')})` }} className="photo-item" />
          </Col>
          <Col className="photo-col photo-col-center">
            <div style={{ backgroundImage: `url(${baseUrl('img/about_photo_2.jpg')})` }} className="photo-item" />
            <div style={{ backgroundImage: `url(${baseUrl('img/about_photo_3.jpg')})` }} className="photo-item" />
          </Col>
          <Col className="photo-col">
            <div style={{ backgroundImage: `url(${baseUrl('img/about_photo_4.jpg')})` }} className="photo-item" />
          </Col>
        </Row>
        <Row className="photo-row">
          <Col className="photo-col">
            <div style={{ backgroundImage: `url(${baseUrl('img/about_photo_5.jpg')})` }} className="photo-item" />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default SectionPhotos;
