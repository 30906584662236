import React, { Component } from 'react';
import SectionHero from '../sections/Technology/SectionHero';
import SectionDescription from '../sections/Technology/SectionDescription';
import SectionTechnologySelection from '../sections/Technology/SectionTechnologySelection';
import SectionImplementation from '../sections/Technology/SectionImplementation';
import SectionPostProduction from '../sections/Technology/SectionPostProduction';
import SectionInfo1 from '../sections/Technology/SectionInfo1';
import SectionInfo2 from '../sections/Technology/SectionInfo2';
import SectionInfo3 from '../sections/Technology/SectionInfo3';
import SectionInfo4 from '../sections/Technology/SectionInfo4';
import SectionInfo5 from '../sections/Technology/SectionInfo5';
import Footer from '../components/Footer';

class Technologies extends Component {
  render() {
    return (
      <div className="page-content technology-page-content">
        <SectionHero />
        <SectionDescription />
        <SectionTechnologySelection />
        <SectionImplementation />
        <SectionPostProduction />
        <SectionInfo1 />
        <SectionInfo2 />
        <SectionInfo3 />
        <SectionInfo4 />
        <SectionInfo5 />
        <Footer />
      </div>
    );
  }
}

export default Technologies;
