import React from 'react';
import { Container } from 'reactstrap';

const SectionFrontend = () => {
  return (
    <section className="section section-light section-technology-desc">
      <Container>
        <p className="section-description">
          We believe that creating a successful product consists of the smart choice of technology and implementation
          techniques, with exceptional post-production support. And in order to make this choice it’s necessary to know
          the market and the end user’s needs. At Alienlab, we know the way about this!{' '}
        </p>
      </Container>
    </section>
  );
};

export default SectionFrontend;
