import React, { Component } from 'react';
import SectionHero from '../sections/Careers/SectionHero';
import SectionContent from '../sections/Careers/SectionContent';
import CareersFooter from '../components/CareersFooter';

class Careers extends Component {
  render() {
    return (
      <div className="page-content careers-page-content">
        <SectionHero />
        <SectionContent />
        <CareersFooter />
      </div>
    );
  }
}

export default Careers;
