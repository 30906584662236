import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import App from './containers/App';
import './styles/index.css';

import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://b93b4186dc0146bf9e46c06d9ce5eb6c@sentry.io/1411861'
});

ReactGA.initialize('UA-119815295-1');

ReactDOM.render(
  <Router>
    <Route path="/" component={App} />
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
