import React from "react";

import { baseUrl } from "../../services/helpers";
import ContactButton from "../../components/ContactButton";

const SectionIntro = () => {
  return (
    <section className="section section-dark section-hero section-intro">
      <div className="container">
        <div className="section-content-wrapper">
          <img
            itemProp="image"
            src={baseUrl("img/world.png")}
            alt="world"
            className="intro-image"
          />
          <h3 className="section-title">INTRO</h3>
          <h1 className="hero-title">THINK OUT OF THE EARTH</h1>
          <h6 className="hero-desc">
            Discover solutions that drive businesses around the world.
          </h6>

          <ContactButton color="light" className="hero-action-btn">
            Let’s talk
          </ContactButton>
        </div>
      </div>
    </section>
  );
};

export default SectionIntro;
