import React, { Component } from 'react';
import SectionHero from '../sections/Services/SectionHero';
import Section1 from '../sections/Services/Section1';
import Section2 from '../sections/Services/Section2';
import Section3 from '../sections/Services/Section3';
import Section4 from '../sections/Services/Section4';
import Section5 from '../sections/Services/Section5';
import Footer from '../components/Footer';

class Services extends Component {
  render() {
    return (
      <div className="page-content services-page-content">
        <SectionHero />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Footer />
      </div>
    );
  }
}

export default Services;
