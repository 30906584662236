import React from "react";
import { Row, Col, Container } from "reactstrap";
import { baseUrl } from "../../services/helpers";

const SectionInfo2 = () => {
  return (
    <section className="section section-dark section-info-dark">
      <Container>
        <div className="heading-group">
          <h4 className="group-title">Work Tactics </h4>
          <p className="section-description">
            Choosing the right project management process is one of the
            cornerstones of successful projects. However, we don’t rely on it as
            the sole pledge for project success. While the process is important,
            we play extra attention to the project requirements, the team and
            company culture to help the fixed process be more effective.
          </p>
        </div>
        <Row>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/path_inverse.svg")}
              alt="platforms"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">Setting off</h3>
            <p className="text-muted">
              To us, the project doesn’t start when the first line of code is
              written. We start our hard work from the moment our clients
              contact us to secure flawless communication while we offer
              different options to ensure their budget, timing and performance
              preferences are met.
            </p>
          </Col>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/path_inverse.svg")}
              alt="production"
              className="rotate-45"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">Agility</h3>
            <p className="text-muted">
              Once the preferences are set, we choose the process. We approach
              each project individually, and adjust the process accordingly.
              While bigger projects run smoothly with Scrum, smaller projects
              are better off with Kanban or even traditional Waterfall. So, we
              tailor the process to project- specific features, the team,
              project duration and timing. The process connects product owners
              with development and creates the perfect balance between the
              requirements of the two.
            </p>
          </Col>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/path_inverse.svg")}
              alt="devices"
              className="rotate-90"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">Accountability</h3>
            <p className="text-muted">
              We practice the principles of flexibility. Each of us has the
              freedom to schedule our work and make decisions without giving up
              on the commitment, so the work is fun and professional. And to
              refine the process, we practice peer feedback at the end of each
              cycle. The transparency of our processes and work ensures
              accountability to our clients, who always feel part of the team.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionInfo2;
