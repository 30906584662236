import React from "react";
import { Row, Col } from "reactstrap";
import FooterNav from "../../components/FooterNav";
import ContactForm from "../../components/ContactForm";

const SectionContacts = () => {
  return (
    <section className="section section-dark section-footer">
      <div className="container">
        <Row>
          <Col md={{ size: 10, offset: 1 }} lg={{ size: 12, offset: 0 }}>
            <h1 className="section-title">CONTACT</h1>
            <h4 className="section-subtitle">
              Feel like we can translate your product idea into a success story?
            </h4>
          </Col>
          <Col
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 0 }}
            className="order-lg-2"
          >
            <ContactForm />
          </Col>
          <Col lg="6" className="order-lg-1">
            <table className="contact-table">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>
                    <a itemProp="email" href="mailto:contact@alienlab.xyz">
                      contact@alienlab.xyz
                    </a>
                  </td>
                </tr>
                {/* <tr>
                  <td>Phone</td>
                  <td>+374 94 766 766</td>
                </tr> */}
                {/* <tr>
                  <td>Social</td>
                  <td>Facebook / Linkedin / Twitter</td>
                </tr> */}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
      <FooterNav />
    </section>
  );
};

export default SectionContacts;
