import React from "react";

import FooterNav from "./FooterNav";
import CareersContactForm from "./CareersContactForm";

const CareersFooter = () => {
  return (
    <section className="section section-dark section-footer">
      <div className="container">
        <div className="footer-content">
          <h1>READY TO WORK WITH US?</h1>
          <p>
            Can’t find your position? Know that we’re always on the lookout for
            great talent. Reach out with your CV, and we’ll include you in our
            talent pool.
          </p>
          <CareersContactForm />
        </div>
      </div>
      <FooterNav />
    </section>
  );
};

export default CareersFooter;
