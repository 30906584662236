import React from "react";
import { Row, Col, Container } from "reactstrap";
import { baseUrl } from "../../services/helpers";

const SectionInfo1 = () => {
  return (
    <section className="section section-light section-info">
      <Container>
        <div className="heading-group">
          <h4 className="group-title">What fits you</h4>
          <p className="section-description">
            The variety of technology skills that we have can be implemented
            based on the ground client’s requirements. This way we can define a
            stack that meets your time, budget and preferred features set.
          </p>
        </div>
        <div className="heading-group">
          <h4 className="group-title">24/7 Support</h4>
          <p className="section-description">
            We put the user in the center of all our development processes and
            any work that we do. Meeting their interests and needs drives our
            day-to-day creativity and hard work. We put special concentration on
            availability and uptime, as well as application response time. And
            to make our cooperation even more convenient, we provide each client
            with well-established infrastructure.
          </p>
        </div>
        <Row>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/icon_platforms.svg")}
              alt="platforms"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">PLATFORMS</h3>
            <p className="text-muted">
              We practice cross-platform development that helps our clients
              reach their target audience and enhance the experience of using
              solutions that are tailored to the best practices of each
              respective platform.{" "}
            </p>
          </Col>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/icon_production.svg")}
              alt="production"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">PRODUCTION</h3>
            <p className="text-muted">
              We are mobile-first in our development approach, we follow trends
              and deliver results independent of screen size. As mobile traffic
              is on the rise, our solutions fully cover all of the devices,
              serving our clients’ needs from A-Z.
            </p>
          </Col>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/icon_devices.svg")}
              alt="devices"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">DEVICES</h3>
            <p className="text-muted">
              As we put the user in the center of our work, performance is the
              second most important point of development after security. So, we
              make sure that performance standards are met at all times.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionInfo1;
