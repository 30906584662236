import React from "react";
import { Row, Col } from "reactstrap";
import { baseUrl } from "../../services/helpers";
import LearnMore from "../../components/LearnMore";
import ContactButton from "../../components/ContactButton";

const SectionOurServices = () => {
  return (
    <section className="section section-dark section-our-services">
      <div className="container">
        <div className="section-content-wrapper">
          <div className="section-content">
            <h1 className="section-title">Our services</h1>
            <h4 className="section-subtitle">
              We cover the full software development lifecycle
            </h4>
            <LearnMore to="/services" inverse={true} />
            <div className="our-services-illustration">
              <div className="step-lines">
                <span />
                <span />
                <span />
                <span />
              </div>
              <ul className="our-service-steps">
                <li className="step-1">
                  <img
                    itemProp="image"
                    src={baseUrl("/img/service-step1.svg")}
                    alt="Planning and design"
                    className="step-image"
                  />
                  <span className="step-desc">Planning and design</span>
                </li>
                <li className="step-2">
                  <img
                    itemProp="image"
                    src={baseUrl("/img/service-step2.svg")}
                    alt="Project Management and collaboration"
                    className="step-image"
                  />
                  <span className="step-desc">
                    Project Management and <br />
                    collaboration
                  </span>
                </li>
                <li className="step-3">
                  <img
                    itemProp="image"
                    src={baseUrl("/img/service-step3.svg")}
                    alt="Development and testing"
                    className="step-image"
                  />
                  <span className="step-desc">Development and testing</span>
                </li>
                <li className="step-4">
                  <img
                    itemProp="image"
                    src={baseUrl("/img/service-step4.svg")}
                    alt="Integration and Continued support"
                    className="step-image"
                  />
                  <span className="step-desc">
                    Integration and Continued <br />
                    support
                  </span>
                </li>
              </ul>
            </div>
            <div className="text-center">
              <ContactButton color="light">GET IN TOUCH</ContactButton>
            </div>
          </div>
          <div className="section-content">
            <Row>
              <Col md={{ size: 9, offset: 3 }} lg={{ size: 6, offset: 5 }}>
                <h1 className="section-title">Technology stack </h1>
                <h4 className="section-subtitle">
                  Alien solutions, real-world sharp technologies
                </h4>
                <p className="section-description my-3">
                  We have professionals from different areas of expertise like
                  big data, mobile, cross-platform development, cloud
                  integration and many more. This enables us to define
                  approaches that can effectively cover our clients’ business
                  needs while staying on time and on budget. We suggest the most
                  suitable choices of user platforms, architecture (scalable
                  microservices based application), development platforms
                  (Meteor, Loopback, Express etc.) and hosting (AWS, Gcloud,
                  DigitalOcean etc.), leading the process from scratch to post
                  production support.
                </p>
                <LearnMore to="/technologies" inverse={true} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <span className="skew-left" />
    </section>
  );
};

export default SectionOurServices;
