import React, { Component } from 'react';
import { Button } from 'reactstrap';

class ContactButton extends Component {
  handleClick = () => {
    document.getElementById('contactForm').scrollIntoView({
      behavior: 'smooth'
    });
  };
  render() {
    return (
      <Button {...this.props} onClick={this.handleClick}>
        {this.props.children}
      </Button>
    );
  }
}

export default ContactButton;
