import React from "react";

const SectionHero = () => {
  return (
    <section className="section section-dark section-hero section-technology-hero">
      <div className="container">
        <div className="section-content-wrapper">
          <h3 className="section-title">TECHNOLOGIES</h3>
          <h1 className="hero-title">Technologies we use</h1>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
