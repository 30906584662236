import React from "react";
import { Row, Col, Container } from "reactstrap";
import { baseUrl } from "../../services/helpers";
import LearnMore from "../../components/LearnMore";

const Section1 = () => {
  return (
    <section className="section section-light section-partners-1">
      <Container>
        <Row className="partner-row extra-image-row">
          <Col lg={6} className="partner-image-col extra">
            <img
              itemProp="image"
              src={baseUrl("img/partner-1.png")}
              alt="partner"
            />
          </Col>
          <Col
            md={{ size: 10, offset: 1 }}
            lg={{ size: 6, offset: 0 }}
            className="align-self-start"
          >
            <h3 className="text-uppercase mb-3">
              We helped X drive 130% more leads through a complete redesign
            </h3>
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante
              metus, ultricies eu arcu eu, blandit accumsan orci. Nam sit amet
              turpis tellus. Cras aliquet ullamcorper tortor vitae ultrices.
            </p>
            <LearnMore to="/services" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section1;
