import React, { Component } from "react";
import SectionIntro from "../sections/Home/SectionIntro";
import SectionAbouUs from "../sections/Home/SectionAbouUs";
import SectionOurServices from "../sections/Home/SectionOurServices";
import SectionPartners from "../sections/Home/SectionPartners";
import SectionContacts from "../sections/Home/SectionContacts";

class Home extends Component {
  render() {
    return (
      <div itemProp="description" className="page-content home-page-content">
        <SectionIntro />
        <SectionAbouUs />
        <SectionOurServices />
        <SectionPartners />
        <SectionContacts />
      </div>
    );
  }
}

export default Home;
