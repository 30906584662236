import React from "react";
import { Row, Col, Container } from "reactstrap";

const Section5 = () => {
  return (
    <section className="section section-light section-steps-5">
      <Container>
        <Row className="service-step">
          <Col lg={{ size: 8, offset: 2 }}>
            <h1 className="section-title" datanumber="5">
              Integration and Continued support
            </h1>
            <p className="section-description text-muted">
              The release is but a phase for us- we stick to the game for
              continuous improvements and 24/7 support.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section5;
