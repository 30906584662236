import React from 'react';
import { Row, Col, Container } from 'reactstrap';

const SectionInfo5 = () => {
  return (
    <section className="section section-light section-info5">
      <Container>
        <Row>
          <Col md="9" className="mb-5">
            <h3 className="mb-4 section-title">Working Culture - Personal Touch</h3>
            <p>
              At Alienlab, we put a lot of effort in creating an awesome working environment
              that has all the conditions for talent to grow. Our tech geeks have all the tools
              they need to work and experiment with new technologies. We encourage proactivity in
              each and every member of our team - be it a suggestion on improving our client’s product
              or a new idea to rock the market. This way all the ends - engineering, tooling, process
              and communication bring to exceptional results.
            </p>
          </Col>
          <Col md={{ size: 9, offset: 3 }} className="py-5">
            <h3 className="mb-4 section-title">On top of the game</h3>
            <p>
              At Alienlab we value expertise and our clients’ businesses. To reach the first we keep
              our knowledge razor-sharp by constant learning, participation in workshops, seminars and
              knowledge sharing sessions. And to reach the second, we simply implement what we have under
              the belt as soon as we blend into your team and take up your tech project.
            </p>
          </Col>
          <Col md="9" className="mt-5">
            <h3 className="mb-4 section-title">The release</h3>
            <p>
              Congratulations! We’ve just released the product! Time to wrap up work and relax, right? Wrong!
              The release and the launch mark just the beginning of our continuous journey to measure, analyze
              and optimize the product! And our transparent processes will help clients follow this journey and
              get involved as they witness the product improve and make a change in the world!
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionInfo5;
