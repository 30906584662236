import React from 'react';
import { Container } from 'reactstrap';

const SectionEfficiency = () => {
  return (
    <section className="section section-light section-efficiency">
      <Container>
        <p className="efficiency-desc text-muted">
          These are just a couple of numbers that show our journey so far, but you can reach out for better insights into our work, and our first overview of your project: 
        </p>
        <ul className="efficiency-counter">
          <li>
            <b>30+</b>
            Clients Served
          </li>
          <li>
            <b>100+</b>
            Apps developed
          </li>
          <li>
            <b>19</b>
            Countries 
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default SectionEfficiency;
