import React from 'react';
import BgMask from '../../components/BgMask';
import { baseUrl } from '../../services/helpers';
import ContactButton from '../../components/ContactButton';

const Section4 = () => {
  return (
    <section className="section section-secondary">
      <BgMask imgPath={baseUrl('img/bg_image_devteam.jpg')} direction="left" size={80} />
      <h2>Our award-winning DEVELOPMENT team is the reason many clients work with us over and over.</h2>
      <ContactButton color="light">GET IN TOUCH</ContactButton>
    </section>
  );
};

export default Section4;
