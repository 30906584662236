import React from 'react';
import ContactButton from '../../components/ContactButton';
import BgMask from '../../components/BgMask';
import { baseUrl } from '../../services/helpers';

const Section4 = () => {
  return (
    <section className="section section-secondary">
      <BgMask imgPath={baseUrl('img/bg_image_dev.jpg')} size={80} />
      <h2>
        We aren’t yet another outsourcing project,
        and we don’t treat your business as yet another order.
        We form dedicated teams to blow life into your idea and guide you all
        the way to implementing your idea into a working application.
        So, we view ourselves as an extension of your team- feeling the
        same way about your success as we feel about ours 
      </h2>
      <ContactButton color="light">GET IN TOUCH</ContactButton>
    </section>
  );
};

export default Section4;
