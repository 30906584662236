import React, { Component, createRef } from 'react';
import { Button, Form, FormGroup, Input, Alert } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    files: [],
    filesLoading: false,
    success: false,
    error: ''
  };
  fileCounter = 0;
  fileInput = createRef();

  componentWillUnmount() {
    if (this.timer) {
      this.clearTimeout(this.timer);
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
    const { name, email, message, files } = this.state;
    axios
      .post('/api/career', { name, email, message, files })
      .then(() => {
        this.setState({ name: '', email: '', message: '', files: [], success: true });
        this.timer = setTimeout(() => this.setState({ success: false }), 3000);
      })
      .catch((error) => {
        this.setState({ error: 'Sorry something went wrong' });
        const alert = `Unsend message from ${name} (${email}): ${message}`;
        Sentry.captureMessage(alert, 'error');
      });
  };

  handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  handleUploadButtonClick = () => this.fileInput.current.click();

  handleReadFile = (file, length) => {
    const { filesLoading } = this.state;
    if (!filesLoading) {
      this.setState({ filesLoading: true });
    }
    const reader = new FileReader();
    reader.onload = () => {
      const { files } = this.state;
      const updatedFileState = [...files, { name: file.name, file: reader.result }];
      const newState = { files: updatedFileState };

      this.fileCounter++;
      if (length === this.fileCounter) {
        newState.filesLoading = false;
      }
      this.setState(newState);
    };
    reader.readAsDataURL(file);
  };

  handleFileUpload = (event) => {
    const { files: inputFiles } = event.target;
    this.fileCounter = 0;
    for (let i = 0; i < inputFiles.length; i++) {
      this.handleReadFile(inputFiles[i], inputFiles.length);
    }
  };

  onDismiss = (index) => {
    const { files } = this.state;
    const filteredFiles = files.filter((file, i) => i !== index);
    return this.setState({ files: filteredFiles });
  };

  renderFiles() {
    const { files } = this.state;
    return files.map(({ name }, index) => (
      <Alert key={name} color="light" toggle={() => this.onDismiss(index)}>
        {name}
      </Alert>
    ));
  }

  render() {
    const { name, email, message, error, success, filesLoading } = this.state;
    return (
      <Form id="contactForm" onSubmit={this.handleSubmit}>
        {success ? <p className="text-success">Thank you. We will contact you soon</p> : null}
        {error ? <p className="text-danger">{error}</p> : null}
        <FormGroup>
          <Input value={name} onChange={this.handleChange} type="text" name="name" placeholder="NAME" required />
        </FormGroup>
        <FormGroup>
          <Input
            value={email}
            onChange={this.handleChange}
            type="email"
            name="email"
            placeholder="EMAIL ADDRESS"
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            value={message}
            onChange={this.handleChange}
            type="textarea"
            name="message"
            placeholder="MESSAGE"
            rows="8"
            required
          />
        </FormGroup>
        <FormGroup>
          <div className="text-left">
            {this.renderFiles()}
            <Button
              disabled={filesLoading}
              color="light"
              size="sm"
              onClick={this.handleUploadButtonClick}
              type="button">
              Add file
            </Button>
            <input
              multiple
              accept="image/*, .pdf,.csv, .doc, .docx, .txt"
              ref={this.fileInput}
              hidden
              onChange={this.handleFileUpload}
              type="file"
              name="message"
            />
          </div>
        </FormGroup>
        <Button color="light">SEND NOW</Button>
      </Form>
    );
  }
}

export default ContactForm;
