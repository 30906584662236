import React from "react";
import { Row, Col, Container } from "reactstrap";

const SectionBelieve = () => {
  return (
    <section className="section section-dark section-believe">
      <Container>
        <h1 className="section-title">We Believe...</h1>
        <Row>
          <Col md={{ size: 6 }} lg={{ size: 3 }}>
            <div className="belive-item" datanumber="1">
              <b>Openness - </b>
              We are open to new ideas, new technologies and new cooperation- in
              the end, this is the fuel to growth and innovation.
            </div>
          </Col>
          <Col md={{ size: 6 }} lg={{ size: 3, offset: 1 }}>
            <div className="belive-item" datanumber="2">
              <b>Transparency - </b>
              We want YOU behind the wheel. When it comes to your project, we
              want you to never feel left out, so we are transparent about all
              our practices, processes and approaches.
            </div>
          </Col>
          <Col md={{ size: 6 }} lg={{ size: 3, offset: 1 }}>
            <div className="belive-item" datanumber="3">
              <b>Quality - </b>
              We dive deep into our clients’ expectations and derive the best of
              what you have in mind. In the end, we do whatever this takes to
              meet those expectations in terms of quality and performance, and
              often, exceed them.
            </div>
          </Col>
          <Col md={{ size: 6 }} lg={{ size: 3 }}>
            <div className="belive-item" datanumber="4">
              <b>Flexibility - </b>A fun and flexible environment is the harbor
              to creativity. And we stick to it in our day to day work and our
              communication with you.
            </div>
          </Col>
          <Col md={{ size: 6 }} lg={{ size: 3, offset: 1 }}>
            <div className="belive-item" datanumber="5">
              <b>Improvement - </b>
              The release is just part of the journey we share with every
              client. We then embark on the support and improvement journey to
              guarantee the best experience for every client.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionBelieve;
