import React from 'react';

const SectionHero = () => {
  return (
    <section className="section section-dark section-hero section-partners-hero">
      <div className="container">
        <div className="section-content-wrapper">
          <h3 className="section-title">PARTNER CASES</h3>
          <h1 className="hero-title">
            Patner cases title <br />
            should be here
          </h1>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
