import React from "react";
import { Container } from "reactstrap";

const SectionPostProduction = () => {
  return (
    <section className="section section-dark section-postproduction">
      <Container>
        <h1 className="section-title">Post-production</h1>
        <p>
          As the product is ready for production, we still have lots of hard
          work ahead!
        </p>
        <p>
          We detect and avoid incidents like user and environment errors by
          performing continuous monitoring of the application. We also work hard
          to make sure incident resolving takes place immediately if issues ever
          arise. This ensures maximal scalability and insight into possible
          business challenges.
        </p>
        <p>
          The heavy utilization of full stack monitoring helps us perceive the
          bigger picture for the product and show the bottlenecks of the
          application.
        </p>
        <p>
          Business intelligence, along with user behavior analytics help to have
          maximal info about the users, analyze large amounts of data and tune
          it to user behavior.
        </p>
      </Container>
    </section>
  );
};

export default SectionPostProduction;
