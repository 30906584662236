import React, { Component } from 'react';
import SectionHero from '../sections/About/SectionHero';
import SectionPhilosophy from '../sections/About/SectionPhilosophy';
import SectionBelieve from '../sections/About/SectionBelieve';
import SectionEfficiency from '../sections/About/SectionEfficiency';
import SectionPhotos from '../sections/About/SectionPhotos';
import Footer from '../components/Footer';

class About extends Component {
  render() {
    return (
      <div className="page-content about-page-content">
        <SectionHero />
        <SectionPhilosophy />
        <SectionBelieve />
        <SectionEfficiency />
        <SectionPhotos />
        <Footer />
      </div>
    );
  }
}

export default About;
