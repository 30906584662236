import React, { Component } from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    success: false,
    error: '',
  };

  componentWillUnmount() {
    if(this.timer) {
      this.clearTimeout(this.timer);
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
    const { name, email, message } = this.state;
    axios.post('/api/contact', { name, email, message })
      .then(() => {
        this.setState({ name: '', email: '', message: '', success: true });
        this.timer = setTimeout(() => this.setState({ success: false }), 3000);
      })
      .catch((error) => {
        this.setState({ error: 'Sorry something went wrong' });
        const alert = `Unsend message from ${name} (${email}): ${message}`;
        Sentry.captureMessage(alert, 'error');
      })
  }

  handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  render() {
    const { name, email, message, error, success } = this.state;
    return (
      <Form id="contactForm" onSubmit={this.handleSubmit}>
        {success ? <p className="text-success">Thank you. We will contact you soon</p> : null}
        {error ? <p className="text-danger">{error}</p> : null}
        <FormGroup>
          <Input value={name} onChange={this.handleChange} type="text" name="name" placeholder="NAME" required />
        </FormGroup>
        <FormGroup>
          <Input value={email} onChange={this.handleChange} type="email" name="email" placeholder="EMAIL ADDRESS" required />
        </FormGroup>
        <FormGroup>
          <Input value={message} onChange={this.handleChange} type="textarea" name="message" placeholder="MESSAGE" rows="8" required />
        </FormGroup>
        <div className="text-center">
          <Button color="light">SEND NOW</Button>
        </div>
      </Form>
    );
  }
}

export default ContactForm;
