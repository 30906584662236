/* eslint-disable no-undef */
import React, { Component } from "react";
import ReactGA from "react-ga";

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const {
        location: { pathname }
      } = this.props;

      // Google Analytics page view
      trackPage(pathname);
    }

    componentWillReceiveProps(nextProps) {
      const {
        location: { pathname }
      } = this.props;
      const currentPage = pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
