import React from "react";
import { Row, Col, Container } from "reactstrap";

const Section1 = () => {
  return (
    <section className="section section-light section-steps-1">
      <Container>
        <Row className="service-step">
          <Col lg="7">
            <h1 className="section-title" datanumber="1">
              PLANNING &amp; STRATEGY
            </h1>
            <p className="section-description text-muted">
              We navigate the business landscape with rich industry expertise
              and digital capabilities – combining scalable architecture and
              results-driven strategy after careful research and planning.
            </p>
          </Col>
          <Col lg={{ size: 4, offset: 1 }} md={{ size: 8 }}>
            <ul className="service-step-options">
              <li>
                <i className="icon icon-check" />
                Market research
              </li>
              <li>
                <i className="icon icon-check" />
                Concepts
              </li>
              <li>
                <i className="icon icon-check" />
                Competitive Landscape
              </li>
              <li>
                <i className="icon icon-check" />
                Idea Validation &amp; Product-Market Fit
              </li>
              <li>
                <i className="icon icon-check" />
                Real User Feedback
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="service-step">
          <Col lg={{ size: 7, offset: 1 }} className="order-lg-2">
            <h3 className="section-title" datanumber="2">
              DESIGN &amp; UX
            </h3>
            <p className="section-description text-muted">
              Our professionals base decisions on careful research rather than
              the gut feeling, serving beautifully crafted designs that perform.
            </p>
          </Col>
          <Col lg={{ size: 4 }} md={{ size: 8 }} className="order-lg-1">
            <ul className="service-step-options">
              <li>
                <i className="icon icon-check" />
                Mobile app design
              </li>
              <li>
                <i className="icon icon-check" />
                Website and web app design
              </li>
              <li>
                <i className="icon icon-check" />
                Prototyping
              </li>
              <li>
                <i className="icon icon-check" />
                Digital product design
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section1;
