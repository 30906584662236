import React from "react";
import { Row, Col, Container } from "reactstrap";

const Section3 = () => {
  return (
    <section className="section section-dark section-steps-2">
      <Container>
        <Row className="service-step">
          <Col lg="7">
            <h1 className="section-title" datanumber="3">
              DEVELOPMENT
            </h1>
            <p className="section-description">
              We work with the clients to single out the right technology stack
              for their individual project, emphasizing security and impeccable
              performance.
            </p>
          </Col>
          <Col md={{ size: 8 }} lg={{ size: 4, offset: 1 }}>
            <ul className="service-step-options">
              <li>
                <i className="icon icon-check" />
                iOS development
              </li>
              <li>
                <i className="icon icon-check" />
                Android development
              </li>
              <li>
                <i className="icon icon-check" />
                Web development
              </li>
              <li>
                <i className="icon icon-check" />
                Software
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="service-step">
          <Col lg={{ size: 7, offset: 1 }} className="order-lg-2">
            <h3 className="section-title" datanumber="4">
              TESTING &amp; QUALITY ASSURANCE
            </h3>
            <p className="section-description">
              Our team of QA engineers makes sure your application performs
              without hindrances and your users receive the best service
              possible.
            </p>
          </Col>
          <Col md={{ size: 8 }} lg={{ size: 4 }} className="order-lg-1">
            <ul className="service-step-options">
              <li>
                <i className="icon icon-check" />
                Functionality testing
              </li>
              <li>
                <i className="icon icon-check" />
                Performance testing
              </li>
              <li>
                <i className="icon icon-check" />
                Usability testing
              </li>
              <li>
                <i className="icon icon-check" />
                Security testing
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section3;
