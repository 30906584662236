import React from 'react';

const SectionHero = () => {
  return (
    <section className="section section-dark section-hero section-services-hero">
      <div className="container">
        <div className="section-content-wrapper">
          <h3 className="section-title">SERVICES</h3>
          <h1 className="hero-title">
            HOW WE TURN VISION <br />
            INTO REALITY
          </h1>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
