import React, { Component } from 'react';
import SectionHero from '../sections/PartnerCases/SectionHero';
import Section1 from '../sections/PartnerCases/Section1';
import Section2 from '../sections/PartnerCases/Section2';
import Section3 from '../sections/PartnerCases/Section3';
import Section4 from '../sections/PartnerCases/Section4';
import Section5 from '../sections/PartnerCases/Section5';
import Footer from '../components/Footer';

class PartnerCases extends Component {
  render() {
    return (
      <div className="page-content parner-page-content">
        <SectionHero />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Footer />
      </div>
    );
  }
}

export default PartnerCases;
