import React from 'react';
import { Link } from 'react-router-dom';

const LearnMore = ({ to, inverse }) => {
  return (
    <Link to={to} className={`learn-more-btn ${inverse ? 'inverse' : ''}`}>
      LEARN MORE <i className="icon icon-right" />
    </Link>
  );
};

export default LearnMore;
