import React from "react";

const SectionHero = () => {
  return (
    <section className="section section-dark section-hero section-about-hero">
      <div className="container">
        <div className="section-content-wrapper">
          <h3 className="section-title">About</h3>
          <h1 itemProp="legalName" className="hero-title">
            SPECIALISTS IN <br />
            SOFTWARE SOLUTIONS <br />
            We are Alienlab
          </h1>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
