import React, { Component } from "react";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";

class SectionContent extends Component {
  state = {
    activeTab: "1"
  };
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };
  render() {
    const { activeTab } = this.state;
    return (
      <section className="section section-light section-career-content">
        <Container>
          <Row>
            <Col lg={{ size: 6 }}>
              <h3 className="career-content-desc">
                What can be more awesome than working on interesting projects
                with talented individuals who know and love what they are doing?
                Please find open positions below.
              </h3>
            </Col>
            <Col md={{ size: 10, offset: 1 }} lg={{ size: 12, offset: 0 }}>
              {/* <div className="tags-wrapper">
                <div className="tags">
                  <Button size="sm" color={activeTab === '1' ? 'dark' : 'light'} onClick={() => this.toggleTab('1')}>
                    HTML, CSS &amp; JavaScript Internship
                  </Button>
                </div>
              </div> */}
              <br />
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <h1 className="section-title">
                    HTML, CSS &amp; JavaScript Internship
                  </h1>
                  <Row>
                    <Col lg={{ size: 6 }}>
                      <p className="section-description text-muted">
                        If you have a goal of learning Javascript from the roots
                        and strengthen the knowledge gained by working with real
                        projects, we’re waiting for you to join us. You will
                        have the unique opportunity to learn how to work as a
                        professional developer and jump-start your career with
                        valuable experience and impressive job responsibility
                        with Alienlab.
                      </p>
                    </Col>
                  </Row>
                  <h3 className="mt-5 mb-3 section-title">If you</h3>
                  <ul className="check-list">
                    <li>
                      <i className="icon icon-check" />
                      Are enthusiastic about coding in JavaScript
                    </li>
                    <li>
                      <i className="icon icon-check" />
                      Are in your last university year or have already graduated
                    </li>
                    <li>
                      <i className="icon icon-check" />
                      Want to learn with a team of expert developers
                    </li>
                    <li>
                      <i className="icon icon-check" />
                      Want to be pros in HTML, CSS, JavaScripts and further
                      continue with more advanced technologies
                    </li>
                    <li>
                      <i className="icon icon-check" />
                      Look for an opportunity to join a great team and work with
                      top-notch techs on diverse successful products
                    </li>
                  </ul>
                  <br />
                  <Row>
                    <Col lg={{ size: 6 }}>
                      <p
                        itemProp="hiringOrganization"
                        className="section-description text-muted"
                      >
                        Send your CV to{" "}
                        <a href="mailto:hr@alienlab.xyz?subject=HTML, CSS and JavaScript Internship Program">
                          <b itemProp="email">hr@alienlab.xyz</b>
                        </a>
                        .
                      </p>
                      <p
                        itemProp="legalName"
                        className="section-description text-muted"
                      >
                        Waiting to see you in our planet of aliens - Alienlab
                      </p>
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId="2">
                  <h3 className="section-title">OVERVIEW</h3>
                  <Row>
                    <Col lg={{ size: 6 }}>
                      <p className="section-description text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu,
                        blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae
                        ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu
                        arcu eu, blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor.
                      </p>
                    </Col>
                    <Col lg={{ size: 6 }}>
                      <p className="section-description text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu,
                        blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae
                        ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu
                        arcu eu, blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor
                        vitae ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus,
                        ultricies eu arcu eu, blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet
                        ullamcorper tortor vitae ultrices.
                      </p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <h3 className="section-title">OVERVIEW</h3>
                  <Row>
                    <Col lg={{ size: 6 }}>
                      <p className="section-description text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu,
                        blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae
                        ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu
                        arcu eu, blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor
                        vitae ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus,
                        ultricies eu arcu eu, blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet
                        ullamcorper tortor vitae ultrices.
                      </p>
                    </Col>
                    <Col lg={{ size: 6 }}>
                      <p className="section-description text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu arcu eu,
                        blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor vitae
                        ultrices. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ante metus, ultricies eu
                        arcu eu, blandit accumsan orci. Nam sit amet turpis tellus. Cras aliquet ullamcorper tortor .
                      </p>
                    </Col>
                  </Row>
                </TabPane> */}
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default SectionContent;
