import React, { Component } from "react";
import {
  Container,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  NavbarToggler
} from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { baseUrl } from "../services/helpers";

class Header extends Component {
  state = {
    scrolled: false,
    collapsed: false
  };

  componentDidMount = () => {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    window.scroll(0, 0);
    this.props.history.listen(() => {
      if (this.state.collapsed) {
        this.closeNavbar();
      }
      window.scroll(0, 0);
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = e => {
    const scrollTop = window.scrollY;
    const offsetHeight = 150;

    if (scrollTop > offsetHeight) {
      if (!this.state.scrolled) {
        this.setState({
          scrolled: true
        });
      }
    } else {
      if (this.state.scrolled) {
        this.setState({
          scrolled: false
        });
      }
    }
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  closeNavbar = () => {
    this.setState({
      collapsed: false
    });
  };

  render() {
    const { scrolled, collapsed } = this.state;
    return (
      <header className={`page-header ${scrolled ? "scrolled" : ""}`}>
        <Navbar
          color={scrolled || collapsed ? "dark" : "none"}
          light
          expand="md"
        >
          <Container>
            <NavbarBrand tag="div">
              <Link to="/">
                <img
                  itemProp="logo"
                  src={baseUrl("img/logo.svg")}
                  alt="Alienlab"
                />
              </Link>
            </NavbarBrand>
            <NavbarToggler
              onClick={this.toggleNavbar}
              className={collapsed ? "active" : ""}
            />
            <Collapse isOpen={collapsed} navbar>
              <Nav className="ml-auto">
                <NavItem>
                  <NavLink to="/services">Services</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/technologies">Technologies</NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink to="/partner-cases">Partner Cases</NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink to="/about">About</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/careers">Careers</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default Header;
