import React from "react";
import { Row, Col, Container } from "reactstrap";
import { baseUrl } from "../../services/helpers";

const SectionInfo1 = () => {
  return (
    <section className="section section-light section-info">
      <Container>
        <div className="heading-group">
          <h4 className="group-title">The Product</h4>
          <p className="section-description">
            Alienlab is a software consulting company where we craft tech
            solutions that perform. Our approach is professional and proactive.
            We tailor your idea to the market, deliver the MVP, and start the
            most important part of our work: testing the product and collecting
            and analyzing feedback to improve it and cover your product
            requirements A-Z.
          </p>
        </div>

        <Row>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/icon_ideation.svg")}
              alt="ideation"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">ideation</h3>
            <p className="text-muted">
              We start the route to your market-changing product from ideation-
              creating the right business strategy and business model together.
              We cover the main targets the product is aimed at and make sure it
              hits them once it hits the market.
            </p>
          </Col>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/icon_roadmapping.svg")}
              alt="roadmapping"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">roadmapping</h3>
            <p className="text-muted">
              After the ideation, roadmapping helps us plan the required
              features and define the timing for delivery. We know the market
              and its changing demand, and we deliver final products that have
              new trendy features!
            </p>
          </Col>
          <Col md="6" lg="4">
            <img
              itemProp="image"
              src={baseUrl("img/icon_mvp.svg")}
              alt="mvp"
              height="70"
            />
            <h3 className="mt-4 text-uppercase">MVP/MLP</h3>
            <p className="text-muted">
              We never call it a day when the development shapes the MVP
              (Minimum Viable Product). As geeks at heart, we take your product
              as our own business and main priority. We make sure to support
              your needs until we reach MLP (Minimum Loveable Product) and well
              beyond.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionInfo1;
