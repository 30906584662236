import React from "react";
import { Container } from "reactstrap";

const SectionImplementation = () => {
  return (
    <section className="section section-light section-implement">
      <Container>
        <h1 className="section-title">Implementation</h1>
        <ul className="implement-list">
          <li>
            We use Test Driven Development techniques, building a fully
            automated testing suite for continuous quality monitoring of every
            single functionality of the product.
          </li>
          <li>
            While automated quality checks are performed, continuous code
            reviews by our experts make sure code quality issues are eliminated
            where automated tools cannot detect the issue.
          </li>
          <li>
            The performance aspect is tackled from the very beginning, so that
            the features operate flawlessly during every development cycle,
            eliminating disappointment on both sides.
          </li>
          <li>
            We also optimize and automate the routine development process
            allowing the team to concentrate more on adding actual business
            values on the client’s side.
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default SectionImplementation;
