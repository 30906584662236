import React, { Component } from 'react';
import { isMobile, isTabletPortrait } from '../services/helpers';

class BgMask extends Component {
  state = {
    angle: 0
  };
  componentDidMount() {
    this.calcAngle();
    window.addEventListener('resize', this.calcAngle);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.calcAngle);
  }
  calcAngle = () => {
    const { size } = this.props;
    this.setState({
      angle: Math.asin(size / Math.sqrt(Math.pow(size, 2) + Math.pow(window.innerWidth, 2))) * 57.2958
    });
  };

  render() {
    const angle = isMobile() || isTabletPortrait() ? this.state.angle / 2 : this.state.angle;
    const isLeft = this.props.direction === 'left';
    const styleMask = {
      transform: `skewY(${isLeft ? -angle : angle}deg)`
    };
    const styleWrapper = {
      transform: `skewY(${isLeft ? angle * 2 : -angle * 2}deg)`
    };
    const styleBgImage = {
      backgroundImage: `url(${this.props.imgPath})`,
      transform: `skewY(${isLeft ? -angle : angle}deg)`,
      transformOrigin: isLeft ? '0 0' : '100% 100%'
    };
    return (
      <div className="section-bg-mask" style={styleMask}>
        <div className="bg-image-wrapper" style={styleWrapper}>
          <span className="bg-image" style={styleBgImage} />
        </div>
      </div>
    );
  }
}

export default BgMask;
