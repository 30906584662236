import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import Home from "../pages/Home";
import About from "../pages/About";
import Careers from "../pages/Careers";
import Services from "../pages/Services";
import Technologies from "../pages/Technologies";
import PartnerCases from "../pages/PartnerCases";

import Header from "../components/Header";

import withTracker from "./withTracker";

class App extends Component {
  componentDidCatch(error, errorInfo) {
    console.log(error);

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <div className="main">
        <Header history={this.props.history} />
        <div className="page-content-wrapper">
          <Switch>
            <Route exact path="/" component={withTracker(Home)} />
            <Route exact path="/about" component={withTracker(About)} />
            <Route exact path="/careers" component={withTracker(Careers)} />
            <Route exact path="/services" component={withTracker(Services)} />
            <Route
              exact
              path="/technologies"
              component={withTracker(Technologies)}
            />
            <Route
              exact
              path="/partner-cases"
              component={withTracker(PartnerCases)}
            />
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
